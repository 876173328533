



















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const ApplicationModule = namespace('ApplicationModule');
import Icon from '../../components/Icon.vue';

@Component({
    components: {
        Icon
    },
})
export default class ApplicationList extends Vue {
    @ApplicationModule.State
    public applicationUsers: any;

    @ApplicationModule.Action
    public loadUsers!: (applicationId:string) => Promise<any[]>;

    @ApplicationModule.Action
    public setPageNumber!: (pageNumber:number) => Promise<any>;

    public showAddApplication = false;
    public placement = 'right';
    public selectedApplicationId = null;
    public mode!: string;
    public totalCount = 0;

    public columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
        },
    ];

    async loadMore(pagination, pageSize) {
        this.setPageNumber(pagination.current);
        const applicationId = this.$route.params.id;
        try {
            await this.loadUsers(applicationId);
        } catch (err) {
            console.log(err);
        }
    }

    async mounted() {
        const applicationId = this.$route.params.id;
        this.setPageNumber(0);
        try {
            await this.loadUsers(applicationId);
            this.totalCount = this.applicationUsers.pagination.totalCount;
        } catch (err) {
            console.log(err);
        }
    }

    onSearch(e) {
        console.log(e);
    }
}
